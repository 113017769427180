import React from "react";

import {
  Box,
  Heading,
  List,
  ListItem,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

import MainLayout from "@/components/Layouts/MainLayout";
import { Meta } from "@/components/Meta";

export default function PrivacyPolicy() {
  const [isLargerThanMd] = useMediaQuery("(min-width: 48em)", {
    ssr: false,
    fallback: true,
  });

  return (
    <MainLayout isLargerThanMd={isLargerThanMd}>
      <Box
        as="section"
        id="privacy-policy"
        my={100}
        width="90%"
        mx="auto"
        fontFamily="Geomanist"
        fontWeight="light"
      >
        <Heading fontWeight="bold" fontFamily="Geomanist">
          Privacy Policy
        </Heading>

        <Box as="article" id="privacy-policy">
          <Text>
            This privacy policy explains how we collect, use, and share your
            personal data when you use our travel search and travel planner app.
          </Text>

          <Text fontWeight="bold">What personal data do we collect?</Text>

          <Text>We collect the following personal data from you:</Text>

          <List listStyleType="initial" ml={5}>
            <ListItem>
              <strong>Profile information:</strong> This includes information
              that you provide when you create a user profile, such as your
              name, email address, date of birth, gender, and travel
              preferences.
            </ListItem>

            <ListItem>
              <strong>Travel data:</strong> This includes information about your
              travel plans, such as your search history, booked flights and
              hotels, and saved destinations.
            </ListItem>

            <ListItem>
              <strong>Device data:</strong> This includes information about the
              device you use to access our app, such as your IP address, browser
              type, and operating system.
            </ListItem>
          </List>

          <Text fontWeight="bold">How do we use your personal data?</Text>

          <Text>We use your personal data to:</Text>
          <List listStyleType="initial" ml={5}>
            <ListItem>
              Provide you with personalized search results and recommendations.
            </ListItem>
            <ListItem>Improve our app and services.</ListItem>
            <ListItem>Comply with legal and regulatory requirements.</ListItem>
            <ListItem>
              Send you notifications about relevant travel information or
              updates we added to app.
            </ListItem>
          </List>

          <Text fontWeight="bold">
            With whom do we share your personal data?
          </Text>

          <Text>
            We share your personal data with third parties only as necessary to
            provide you with the services you have requested, or as required by
            law. For example, we may share your personal data with:
          </Text>

          <List listStyleType="initial" ml={5}>
            <ListItem>Payment processors, to process your payments.</ListItem>
            <ListItem>
              Analytics providers, to help us improve our app and services.
            </ListItem>
            <ListItem>
              Law enforcement authorities, as required by law.
            </ListItem>
          </List>

          <Text fontWeight="bold">How do we protect your personal data?</Text>

          <Text>
            We use a variety of security measures to protect your personal data
            from unauthorized access, use, or disclosure. These measures
            include:
          </Text>

          <List listStyleType="initial" ml={5}>
            <ListItem>
              Data encryption: Your personal data is encrypted at rest and in
              transit.
            </ListItem>
            <ListItem>
              Access controls: Only authorized employees have access to your
              personal data.
            </ListItem>
            <ListItem>Restrictions on access to your personal data.</ListItem>
            <ListItem>
              Monitoring of our systems for possible vulnerabilities and
              attacks.
            </ListItem>
            <ListItem>
              Using a secure server and database infrastructure to store your
              personal data.
            </ListItem>
            <ListItem>
              Regular backups of your personal data to ensure data availability.
            </ListItem>
            <ListItem>
              Using latest security updates for our app and services.
            </ListItem>
          </List>

          <Text fontWeight="bold">Your choices</Text>

          <Text>
            You have the following choices with respect to your personal data:
          </Text>

          <List listStyleType="initial" ml={5}>
            <ListItem>You can choose to create a user profile or not.</ListItem>
            <ListItem>
              You can choose to share your travel data with us or not.
            </ListItem>
            <ListItem>
              You can choose to receive notifications from us or not.
            </ListItem>
            <ListItem>
              You can request access to your personal data and have it corrected
              or deleted.
            </ListItem>
          </List>

          <Text>
            To exercise your choices, please contact us at{" "}
            <a href="mailto:info@weelet.io">info@weelet.io</a>.
          </Text>

          <Text fontWeight="bold">Changes to this privacy policy</Text>

          <Text>
            We may update this privacy policy from time to time. If we make any
            significant changes, we will notify you through the app or by email.
          </Text>

          <Text fontWeight="bold">Contact us</Text>

          <Text>
            If you have any questions or concerns about our privacy policy or
            practices, please contact us at{" "}
            <a href="mailto:info@weelet.io">info@weelet.io</a>.
          </Text>
        </Box>
      </Box>
    </MainLayout>
  );
}

export const Head = () => (
  <Meta
    title="Weeletio: Privacy Policy"
    description="With Weeletio you can explore unique travel destinations, avoid tourist traps, experience authentic local cultures, read reviews from locals, and even save money."
    keywords="travel, plan, vacation, trip, explore, discover, search, planner, guide, place, tours, activities, privacy policy"
    image="https://storage.googleapis.com/weeletio-social-media/meta-image.png"
    url="https://weelet.io/privacy-policy"
  />
);
