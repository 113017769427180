import React from "react";

interface Props {
  description: string;
  image: string;
  title: string;
  keywords: string;
  url: string;
}

const Meta: React.FC<Props> = ({
  description,
  title,
  image,
  keywords,
  url,
}: Props) => (
  <>
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="author" content="Weeletio" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
    <meta property="twitter:url" content={url} />

    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta property="og:keywords" content={keywords} />

    <meta
      name="revised"
      content={`Weeletio, ${new Date().getMonth()}/${new Date().getDay()}/${new Date().getFullYear()}`}
    />
  </>
);

export default Meta;
